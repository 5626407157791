<template>
  <div id="app">    
    <div class="logo-bar">
      <h1>MODA TREND TEKSTİL</h1>
    </div>
    <div class="wrapper">
    <div class="resimler">
      <img src="~@/assets/resim1.jpeg" alt="">
      <img src="~@/assets/resim2.jpeg" alt="">
      <img src="~@/assets/resim3.jpeg" alt="">
      <img src="~@/assets/resim4.jpeg" alt="">
      <img src="~@/assets/resim5.jpeg" alt="">
      <img src="~@/assets/resim6.jpeg" alt="">
      <img src="~@/assets/resim7.jpeg" alt="">
      <img src="~@/assets/resim8.jpeg" alt="">
      <img src="~@/assets/resim9.jpeg" alt="">
      <img src="~@/assets/resim10.jpeg" alt="">
      <img src="~@/assets/resim11.jpeg" alt="">
      <img src="~@/assets/resim12.jpeg" alt="">
      <img src="~@/assets/resim13.jpeg" alt="">
      <img src="~@/assets/resim14.jpeg" alt="">
      <img src="~@/assets/resim15.jpeg" alt="">
      <img src="~@/assets/resim16.jpeg" alt="">
      <img src="~@/assets/resim17.jpeg" alt="">
      <img src="~@/assets/resim18.jpeg" alt="">
      <img src="~@/assets/resim19.jpeg" alt="">
    </div>
  </div>
  </div>
</template>

<script>


export default {
  name: 'App',
  components: {
  }
}
</script>

<style lang="scss">
*{
  margin:0;
  padding:0;
  box-sizing: border-box;
}
.wrapper{
  display: flex;
  justify-content: center;
}
.logo-bar{
  width:100%;
  height:80px;
  display: flex;
  align-items:center;
  justify-content: center;
  background:gray;
  text-align: center;
}
.resimler {
  display:flex;
  flex-wrap: wrap;
  max-width:1200px;
  justify-content: center;

  img {
    width:320px;
    height: 320px;
    object-fit: cover;
    margin:10px;
  }
}
</style>
